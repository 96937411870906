exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-biografiko-js": () => import("./../../../src/pages/biografiko.js" /* webpackChunkName: "component---src-pages-biografiko-js" */),
  "component---src-pages-epemvaseis-js": () => import("./../../../src/pages/epemvaseis.js" /* webpackChunkName: "component---src-pages-epemvaseis-js" */),
  "component---src-pages-epikoinonia-js": () => import("./../../../src/pages/epikoinonia.js" /* webpackChunkName: "component---src-pages-epikoinonia-js" */),
  "component---src-pages-iatreio-js": () => import("./../../../src/pages/iatreio.js" /* webpackChunkName: "component---src-pages-iatreio-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-styleguide-js": () => import("./../../../src/pages/styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-js" */),
  "component---src-pages-ypiresies-js": () => import("./../../../src/pages/ypiresies.js" /* webpackChunkName: "component---src-pages-ypiresies-js" */)
}

